<template>
  <div class="CountRealTime">
    <a ref="download" style="display: none" />
    <h2>实时考勤</h2>
    <el-table :data="listData" style="margin-top: 20px">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column prop="date" label="出勤日期"> </el-table-column>
      <!-- <el-table-column prop="" label="在场人数"> </el-table-column> -->
      <el-table-column prop="attendNum" label="考勤人数">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="handleListDataSub(scope.row.date, 0)">{{
            scope.row.attendNum
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="absenceNum" label="未考勤人数">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="handleListDataSub(scope.row.date, 1)">{{
            scope.row.absenceNum
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="inNum" label="当日进场">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="handleListInOutDetails(scope.row.date, 0)">{{
            scope.row.inNum
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="outNum" label="当日退场">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="handleListInOutDetails(scope.row.date, 1)">{{
            scope.row.outNum
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="right" width="160">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleExport(scope.row)" :disabled="onCheckBtnAuthority('CountRealTimeExport')">导出当日考勤数据</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total, sizes, prev, pager, next"
      :total="totals"
      :page-size="pageSize"
      :current-page.sync="pageNum"
      @current-change="onGetListData"
      @size-change="handleSizeChange"
      :page-sizes="[10, 50, 100, 200, 500]"
    >
    </el-pagination>

    <el-dialog
      title="考勤详情"
      :visible.sync="showListDataSub"
      width="80%"
      :before-close="handleCloseListDataSub"
    >
      <el-table :data="listDataSub" style="margin-top: 20px">
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="workerName" label="姓名"> </el-table-column>
        <el-table-column prop="idCard" label="身份证号"> </el-table-column>
        <el-table-column prop="typeOfWorkStr" label="工种"> </el-table-column>
        <el-table-column prop="groupName" label="所属班组"> </el-table-column>
        <el-table-column prop="firmName" label="所属企业"> </el-table-column>
        <el-table-column prop="attendanceDate" label="考勤日期"> </el-table-column>
        <el-table-column prop="admissionTime" label="入场时间"> </el-table-column>
        <el-table-column prop="appearanceTime" label="出场时间"> </el-table-column>
      </el-table>
      <el-pagination
        layout="total, sizes, prev, pager, next"
        :total="totalsSub"
        :page-size="pageSizeSub"
        :current-page.sync="pageNumSub"
        @current-change="onGetListDataSub"
        @size-change="handleSizeChangeSub"
        :page-sizes="[10, 50, 100, 200, 500]"
      >
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleCloseListDataSub"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="进退场详情"
      :visible.sync="showInOutDetails"
      width="80%"
      :before-close="handleCloseInOutDetails"
    >
      <el-table :data="inOutDetailsList" style="margin-top: 20px">
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="workerName" label="姓名"> </el-table-column>
        <el-table-column prop="idcardNumber" label="身份证号"> </el-table-column>
        <el-table-column prop="recordDate" label="时间"> </el-table-column>
        <el-table-column prop="rnStatusStr" label="进退场"> </el-table-column>
      </el-table>
      <el-pagination
        layout="total, sizes, prev, pager, next"
        :total="totalsSub"
        :page-size="pageSizeSub"
        :current-page.sync="pageNumSub"
        @current-change="onGetInOutDetails"
        @size-change="handleSizeChangeInOutDetails"
        :page-sizes="[10, 50, 100, 200, 500]"
      >
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleCloseInOutDetails"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
    
<style lang="less" scoped>
.CountRealTime {
  padding: 20px;
}
</style>
    
<script>
export default {
  name: "CountRealTime",
  components: {},
  data() {
    return {
      pageBtn: [],
      pageSize: 10,
      pageNum: 1,
      totals: 0,
      listData: [],

      pageSizeSub: 10,
      pageNumSub: 1,
      totalsSub: 0,
      listDataSub: [],
      showListDataSub: false,
      attendanceDate: null,
      attendanceStatus: null,
      rnStatus: null,
      showInOutDetails: false,
      inOutDetailsList: []
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.onGetPageBtn();
    this.onGetListData();
  },
  methods: {
    goto: function (path) {
      this.$router.push({
        name: path,
      });
    },
    // 获取当前页面按钮权限
    onGetPageBtn: function () {
      var that = this;
      var data = {
        id: "1688760711047049217",
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/menu/buttons",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.pageBtn = res.data;
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 检查按钮权限
    onCheckBtnAuthority: function (code) {
      var disable = true;
      if (this.pageBtn.length) {
        this.pageBtn[0].children.forEach((item) => {
          if (item.code == code) {
            disable = false;
          }
        });
      }
      return disable;
    },
    // 获取主列表数据
    onGetListData: function () {
      var that = this;
      var data = {
        projectId: localStorage.getItem("projectId"),
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/kqRealtimeStatistics/realTime",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.listData = res.data.dataList;
            that.totals = res.data.totals;
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 切换分页大小
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.onGetListData();
    },
    // 考勤详请分页大小
    handleListDataSub: function (attendanceDate, attendanceStatus) {
      this.attendanceDate = attendanceDate
      this.attendanceStatus = attendanceStatus
      this.onGetListDataSub()
    },
    // 进退场分页大小
    handleSizeChangeInOutDetails: function (attendanceDate, rnStatus) {
      this.attendanceDate = attendanceDate
      this.rnStatus = rnStatus
      this.onGetInOutDetails()
    },
    // 获取考勤详情
    onGetListDataSub: function () {
      var that = this;
      var data = {
        projectId: localStorage.getItem('projectId'),
        attendanceDate: this.attendanceDate,
        attendanceStatus: this.attendanceStatus,
        pageNum: this.pageNumSub,
        pageSize: this.pageSizeSub,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/kqRealtimeStatistics/attendanceDetails",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.listDataSub = res.data.dataList;
            that.totalsSub = res.data.totals;
            that.showListDataSub = true;
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 进退场详情
    handleListInOutDetails: function (attendanceDate, rnStatus) {
      this.attendanceDate = attendanceDate
      this.rnStatus = rnStatus
      this.onGetInOutDetails()
    },
    // 获取进退场详情
    onGetInOutDetails: function () {
      var that = this;
      var data = {
        projectId: localStorage.getItem('projectId'),
        attendanceDate: this.attendanceDate,
        rnStatus: this.rnStatus,
        pageNum: this.pageNumSub,
        pageSize: this.pageSizeSub,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/kqRealtimeStatistics/inOutDetails",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.inOutDetailsList = res.data.dataList;
            that.totalsSub = res.data.totals;
            that.showInOutDetails = true;
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSizeChangeSub(val) {
      this.pageSizeSub = val;
      this.pageNumSub = 1;
      this.onGetListDataSub();
    },
    // 关闭考情详情
    handleCloseListDataSub: function () {
      this.pageSizeSub = 10
      this.pageNumSub = 1
      this.totalsSub = 0
      this.listDataSub = []
      this.showListDataSub = false
      this.attendanceDate = null
      this.attendanceStatus = null
    },
    // 关闭进退场详情
    handleCloseInOutDetails: function () {
      this.pageSizeSub = 10
      this.pageNumSub = 1
      this.totalsSub = 0
      this.inOutDetailsList = []
      this.showInOutDetails = false
      this.attendanceDate = null
      this.rnStatus = null
    },
    // 导出
    handleExport: function (row) {
      var that = this;
      var timestamp = new Date().getTime()
      var data = {
        projectId: localStorage.getItem('projectId'),
        dateTime : row.date
      };
      that
        .$axiosAdmin({
          method: "post",
          responseType: "blob",
          url: "api/qingxin-platform-workers/ExcelImport/DayKQExcelExport",
          data: JSON.stringify(data),
        })
        .then((res) => {
          const blob = res;
          that.$refs.download.href = window.URL.createObjectURL(new Blob([blob], {type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
          that.$refs.download.download = `${row.date}实时考勤 ${timestamp}.xlsx`;
          that.$refs.download.click();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
    